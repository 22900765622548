import NextLink from 'next/link';

import { PublicPageLayout } from '../../PublicPageLayout';

const ErrorPage = () => {
  return (
    <PublicPageLayout title="Oops, this page can't be found">
      <p className="text-center">
        It looks like the page you searched for has been moved, jump back to the{' '}
        <NextLink className="text-underline" href="/">
          <span>home page</span>
        </NextLink>{' '}
        or have a read of our blog{' '}
        <NextLink className="text-underline" href="/blog">
          <span>here</span>
        </NextLink>
        .
      </p>
    </PublicPageLayout>
  );
};

export { ErrorPage };
